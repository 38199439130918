import React from 'react';
import Home from './Home';
import Service from './ServicesOld';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HomeContainer = () => {
  return (
    <Container>
      <Row>
        {/* Left Section */}
        <Col md={6}>
          <div style={{  padding: '20px',  }}>
            <Home />
          </div>
        </Col>

        {/* Right Section */}
        <Col md={6}>
          <div style={{  padding: '20px',  }}>
            <Service />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeContainer;