import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import './Contact.css';

const Contact = () => {
   return (
    <Container className="d-flex justify-content-center">
    <div>
      <h4>
        Please feel free to contact us by phone at  9790847408
        <br />
        <span className="or-text">or</span>
        <br />
        drop us an email at{' '}
        <a href="mailto:contact@imc.com">contact@imc.com</a>.
      </h4>
    </div>
  </Container>
  );
};

export default Contact;
